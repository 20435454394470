/*
 * @Author: Ying Zhang
 * @Date: 2022-08-22 17:28:58
 * @LastEditors: Ying Zhang
 * @Description:
 * @FilePath: /captain/src/states/KeyPoint/index.ts
 * 道阻且长，行则将至
 */

import { atomWithImmer } from "jotai/immer";

export const initRows = atomWithImmer({
  init: [
    {
      id: 1,
      factor: 0.125,
      support: 0,
      pressure: 0,
      newHigh: 0,
    },
    { id: 2, factor: 0.2, support: 0, pressure: 0, newHigh: 0 },
    { id: 3, factor: 0.333333, support: 0, pressure: 0, newHigh: 0 },

    { id: 4, factor: 0.382, support: 0, pressure: 0, newHigh: 0 },
    { id: 5, factor: 0.5, support: 0, pressure: 0, newHigh: 0 },
    {
      id: 6,
      factor: 0.618,
      support: 0,
      pressure: 0,
      newHigh: 0,
    },
    { id: 7, factor: 0.666666, support: 0, pressure: 0, newHigh: 0 },
    { id: 8, factor: 0.8, support: 0, pressure: 0, newHigh: 0 },
    { id: 9, factor: 0.875, support: 0, pressure: 0, newHigh: 0 },
  ],
});
