/*
 * @Author: Ying Zhang
 * @Date: 2022-08-21 14:55:06
 * @LastEditors: Ying Zhang
 * @Description:
 * @FilePath: /captain/src/components/lib/Layout/index.tsx
 * 道阻且长，行则将至
 */

import React from "react";
import cn from "classnames";

interface Props {
  children: React.ReactNode;
  className?: string;
}

export default function Layout({ children, className }: Props) {
  return (
    <>
      <div className={cn("w-[80%] m-auto ", className)}>{children}</div>
    </>
  );
}
