/*
 * @Author: Ying Zhang
 * @Date: 2022-08-21 13:53:48
 * @LastEditors: Ying Zhang
 * @Description:
 * @FilePath: /captain/src/pages/MainLayout/index.tsx
 * 道阻且长，行则将至
 */
import { FC } from "react";
import { Outlet } from "react-router-dom";
const MainLayout: FC = (): JSX.Element => {
  return (
    <>
      <div>
        <Outlet />
      </div>
    </>
  );
};
export default MainLayout;
