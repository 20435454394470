/*
 * @Author: Ying Zhang
 * @Date: 2022-08-21 14:14:36
 * @LastEditors: Ying Zhang
 * @Description:
 * @FilePath: /captain/src/components/lib/PageNotFoundView.tsx
 * 道阻且长，行则将至
 */

import { useEffect } from "react";

const PageNotFoundView = () => {
  useEffect(() => {
    window.location.href = "https://weibo.com/u/1880018152?tabtype=feed";
  }, []);
  return <></>;
};
export default PageNotFoundView;
