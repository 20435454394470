/*
 * @Author: Ying Zhang
 * @Date: 2022-08-21 17:03:35
 * @LastEditors: Ying Zhang
 * @Description:
 * @FilePath: /captain/src/components/Header/index.tsx
 * 道阻且长，行则将至
 */

import logo from "@assets/images/logo.png";
import gsc from "@assets/images/gdc.png";

export default function index() {
  return (
    <>
      <header className="flex flex-col justify-center items-center">
        <div className="text-[1.5rem] font-bold flex items-center">
          <img
            src={logo}
            alt=""
            className="w-[1.8rem] h-[1.8rem] mr-1 font-rubikLight"
          />
          <h1 className="flex flex-col">
            <span>股盗船长</span>
            {/* <span className="text-[.2rem] font-normal text-center text-red-500 font-rubikMedium  animate__animated animate__pulse">
              【关键点计算】
            </span> */}
          </h1>

          <img
            src={gsc}
            alt=""
            className="h-[2.2rem] w-auto animate__animated animate__zoomInDown "
          />
        </div>
      </header>
    </>
  );
}
