/*
 * @Author: Ying Zhang
 * @Date: 2022-08-21 13:50:11
 * @LastEditors: Ying Zhang
 * @Description:
 * @FilePath: /captain/src/routes/index.tsx
 * 道阻且长，行则将至
 */

import { Suspense } from "react";
import { RouteObject } from "react-router-dom";

import Loading from "@components/lib/Loading";
import PageNotFoundView from "@components/lib/PageNotFoundView";
import KeyPoint from "@pages/KeyPoint";
import MainLayout from "@pages/MainLayout";

const Routes: RouteObject[] = [];

const Layout = () => {
  return (
    <Suspense fallback={<Loading />}>
      <MainLayout />
    </Suspense>
  );
};

const mainRoutes = {
  path: "/",
  element: <Layout />,
  children: [
    {
      path: "*",
      element: <PageNotFoundView />,
    },
    {
      path: "/",
      element: <KeyPoint />,
    },
    {
      path: "keyPoint",
      element: <KeyPoint />,
    },
  ],
};
Routes.push(mainRoutes);

export default Routes;
