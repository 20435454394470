/*
 * @Author: Ying Zhang
 * @Date: 2022-08-21 18:48:37
 * @LastEditors: Ying Zhang
 * @Description:
 * @FilePath: /captain/src/components/KeyPoint/Calculate.tsx
 * 道阻且长，行则将至
 */

import { useFormik } from "formik";
import { Box, Grid, InputAdornment, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import { DataGrid, GridColDef, GridRowClassNameParams } from "@mui/x-data-grid";
import { Avatar } from "@mui/material";

import head from "@assets/images/head.png";
// import kobe24 from "@assets/images/kobe24.png";
import look from "@assets/images/look.png";

import { useImmerAtom } from "jotai/immer";
import { initRows } from "@states/KeyPoint";
import { Sailing } from "@mui/icons-material";

export default function Calculate() {
  const columns: GridColDef[] = [
    {
      field: "factor",
      width: 80,
      type: "number",
      renderHeader: () => (
        <strong className="flex items-center text-[.75rem]">
          <Avatar
            alt="captain"
            src={head}
            sx={{ width: 18, height: 18 }}
            className="!mr-1"
          />
          {"系数 "}
        </strong>
      ),
    },
    {
      field: "support",
      // headerName: "回调支撑位",
      flex: 0.8,
      editable: false,
      type: "number",
      renderHeader: () => (
        <strong className="flex items-center text-[.75rem]">
          {"回调支撑位 "}
        </strong>
      ),
    },
    {
      field: "pressure",
      // headerName: "反弹压力位",
      flex: 0.8,
      editable: false,
      type: "number",
      renderHeader: () => (
        <strong className="flex items-center text-[.75rem]">
          {"反弹压力位 "}
        </strong>
      ),
    },

    // 新高
    {
      field: "newHigh",
      // headerName: "阶段新高位",
      flex: 0.8,
      editable: false,
      type: "number",
      renderHeader: () => (
        <strong className="flex items-center text-[.75rem]">
          {"阶段新高位 "}
        </strong>
      ),
    },
  ];

  const [rows, setRows] = useImmerAtom(initRows);

  const formik = useFormik({
    initialValues: {
      highSpot: "",
      lowSpot: "",
    },
    // validationSchema: Yup.object({
    //   highSpot: Yup.string()
    //     .max(15, "Must be 15 characters or less")
    //     .required("Required"),
    //   lowSpot: Yup.string()
    //     .max(20, "Must be 20 characters or less")
    //     .required("Required"),
    // }),
    onSubmit: (values) => {
      setRows((prevRows) => {
        const newRows = prevRows.init.map((row, index) => {
          // Number(values.highSpot)
          // Number(values.lowSpot)
          const supportNum =
            Math.pow(Number(values.lowSpot), row.factor) *
            Math.pow(Number(values.highSpot), 1 - row.factor);

          const pressureNum =
            Math.pow(Number(values.highSpot), row.factor) *
            Math.pow(Number(values.lowSpot), 1 - row.factor);

          const markNum =
            Number(values.highSpot) /
            Math.pow(Number(values.lowSpot), 1 - row.factor);

          const newHighNum = Math.pow(markNum, 1 / row.factor);

          return {
            ...row,
            support: Number(supportNum.toFixed(2)),
            pressure: Number(pressureNum.toFixed(2)),
            newHigh: row.factor > 0.49 ? Number(newHighNum.toFixed(2)) : 0,
            // newHigh: Number(newHighNum.toFixed(2)),
          };
        });
        return { ...prevRows, init: newRows };
      });
    },
  });

  return (
    // getFieldProps：代替 onChange, onBlur, value, checked
    <div>
      <form onSubmit={formik.handleSubmit} className="flex flex-col mt-6 mb-3">
        <Box sx={{ flexGrow: 1 }} className="!mb-5">
          <TextField
            className="!text-[1.2rem] !text-[#1976d2] !flex-1"
            fullWidth
            id="input-with-icon-textfield"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Avatar
                    alt="captain"
                    src={look}
                    sx={{ width: 18, height: 18 }}
                    className="!mr-1"
                  />
                </InputAdornment>
              ),
            }}
            label=""
            placeholder="current stock"
            variant="standard"
            size="small"
          />
        </Box>

        <Box sx={{ flexGrow: 1 }} className="!mb-3">
          <Grid container spacing={3}>
            <Grid item xs={6} md={6}>
              <TextField
                id="outlined-number"
                required
                size="small"
                label="阶段-高点 / C点"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                {...formik.getFieldProps("highSpot")}
              />
            </Grid>

            <Grid item xs={6} md={6}>
              <TextField
                id="outlined-number"
                required
                size="small"
                label="阶段-低点"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                {...formik.getFieldProps("lowSpot")}
              />
            </Grid>
          </Grid>
        </Box>

        <Button type="submit" variant="outlined" size="medium" color="primary">
          <Sailing className="!w-[.9rem] !h-[.9rem] mr-[.3125rem]" />
          关键点计算
        </Button>
      </form>

      <Box
        sx={{
          width: "100%",
          "& .importance": {
            backgroundColor: "#b9d5ff91",
            color: "#1a3e72",
            height: "1rem",
          },
        }}
      >
        <DataGrid
          rows={rows.init}
          columns={columns}
          autoHeight
          getRowId={(row) => row.id}
          pageSize={10}
          hideFooter={true}
          rowHeight={42}
          experimentalFeatures={{ newEditingApi: true }}
          getRowClassName={(params: GridRowClassNameParams) => {
            if (
              params.row.factor == 0.5 ||
              params.row.factor == 0.618 ||
              params.row.factor == 0.875
            ) {
              return "importance";
            } else {
              return "";
            }
          }}
        />
      </Box>
    </div>
  );
}
