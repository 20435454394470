import { useRoutes } from "react-router-dom";
import routes from "../routes";

import "./App.css";
import "animate.css";

function App() {
  let element = useRoutes(routes);

  return <>{element}</>;
}

export default App;
