/*
 * @Author: Ying Zhang
 * @Date: 2022-08-21 13:48:55
 * @LastEditors: Ying Zhang
 * @Description:
 * @FilePath: /captain/src/pages/KeyPoint/index.tsx
 * 道阻且长，行则将至
 */

import Layout from "@components/lib/Layout";
import Header from "@components/Header";
import Calculate from "@components/KeyPoint/Calculate";
import { Avatar, Divider } from "@mui/material";
import yuanbao from "@assets/images/yuanbao.png";

export default function KeyPoint() {
  return (
    <Layout className="mt-[1.5rem]">
      <Header></Header>
      <main className="flex flex-col justify-center items-center box-border ">
        <Calculate></Calculate>
      </main>

      <footer className="mt-[1.6rem]">
        <Divider>
          <Avatar
            alt="captain"
            src={yuanbao}
            sx={{ width: 18, height: 18 }}
            className="!mr-1"
          />
        </Divider>
      </footer>
    </Layout>
  );
}
